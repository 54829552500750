import * as React from "react";
import { SeoProps } from "@layouts/Seo";
import { BusinessTracking } from "@components/business/tracking/BusinessTracking";
import ToolLayout from "@layouts/ToolLayout";
import { Container } from "react-bootstrap";

const SEO: SeoProps = {
  title:
    "Wan!Pass（ワンパス）-約560万世帯の愛犬家を無料で集客で集客するならワンパス！",
  noIndexNoFollow: true,
};

const DonePage = () => {
  return (
    <ToolLayout seo={SEO} type="business">
      <BusinessTracking.ConversionPoint />
      <Container fluid style={{ maxWidth: 600, padding: 0 }}>
        お申し込み誠にありがとうございました。
        <br />
        <br />
        今後の流れにつきまして、連絡先メールアドレスに送付いたしました。
        <br />
        ご一読いただけますと幸いです。
      </Container>
    </ToolLayout>
  );
};

export default DonePage;
